import { useState } from 'react';
import { Turnstile } from '@marsidev/react-turnstile';
import Modal from 'react-modal';
import AppointmentScheduler from './AppointmentScheduler';
import EmailRegistration from './EmailRegistration';

function SignUpForm() {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTurnstileValid, setTurnstileValid] = useState(false);

  return (
    <div className="">
      { /* -- <iframe width="420" height="315"
        src="https://www.youtube.com/embed/tRHhyocsbv8">
      </iframe> --> */}
      <div className="pt-4 grid grid-cols-1 md:grid-cols-3 grid-flow-row gap-4">
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 border-secondary">
        <div>
            blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah 
        </div>
        <div class="flex justify-center">
          <button
            className="btn btn-primary"
            onClick={() => setIsModalOpen(true)}>
            
            Sign Up For Interview
          </button>
        </div>
        <Modal
          isOpen={isModalOpen}
          contentLabel="Example Modal"
        >
          <button
            className="btn btn-secondary float-right"
            onClick={() => setIsModalOpen(false)}>
            
            X
          </button>
          
          <AppointmentScheduler isTurnstileValid={isTurnstileValid} setTurnstileValid={setTurnstileValid}/>
        </Modal>
      </div>
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 border-secondary">
          <EmailRegistration isTurnstileValid={isTurnstileValid} setTurnstileValid={setTurnstileValid} />
        </div>
        <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 border-secondary">
        <div>
            blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah blah 
        </div>
          <div class="flex justify-center">
            <a
                className="btn btn-primary"
                href="https://venmo.com/u/uheart"
                target="_blank">
                
                
                Contribute on Venmo
              </a>
            </div>
        </div>
      </div>
    </div>
  );
}

export default SignUpForm;