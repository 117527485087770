import { useState, useEffect } from 'react';

function AppointmentScheduler({isTurnstileValid, setTurnstileValid}) {
  const interviewConditions = [
    {
      name: "ageReq",
      displayText: "I am between 22 and 35 years of age"
    },
    {
      name: "wantsRelationshipReq",
      displayText: "I think about being in a relationship at least once a week"
    }
  ];
  const mCalSrc = 'https://calendar.google.com/calendar/appointments/schedules/AcZssZ1cqOKyecqxpylHQ1vtvscXT13Wez90-g6z6iqbGKIJ_xklWNIzgEq12SL7tlFbNP080gjVkWT_?gv=true';
  const fCalSrc = 'https://calendar.google.com/calendar/appointments/schedules/AcZssZ3sQU1k664hBlktyT13SmoxMzI0FeASMkTdGunv0xYJlJGV0r1UecxQtGg0HFh8sVtelxFb2az7?gv=true';
  const [isSchedulerDisplayed, setDisplayScheduler] = useState(false);
  const [isConfirmButtonEnabled, setEnableConfirmButton] = useState(false);
  const [calSrc, setCalSrc] = useState('');
  const [interviewConditionsState, setinterviewConditionsState] = useState(
    new Array(interviewConditions.length).fill(false)
  );
  useEffect(() => {
    setEnableConfirmButton(areInterviewConditionsToggled() && calSrc !== '');
  }, [interviewConditionsState]);
  useEffect(() => {
    setEnableConfirmButton(areInterviewConditionsToggled() && calSrc !== '');
  }, [calSrc]);

  function displayScheduler() {
    setDisplayScheduler(true);
  }

  function genderSelectionChanged(genderSelection) {
    if(genderSelection == "male") {
      setCalSrc(mCalSrc);
    } else if (genderSelection == "female") {
      setCalSrc(fCalSrc);
    } else {
      var today = new Date();
      var dd = String(today.getDate()).padStart(2, '0');
      if(dd%2 == 0) {
        setCalSrc(mCalSrc);
      } else {
        setCalSrc(fCalSrc);
      }
    }
  }

  function interviewConditionToggled(indexOfToggledItem) {

    const updatedCheckedState = interviewConditionsState.map((item, index) => {
        if(index == indexOfToggledItem) {
          return !item;
        } else {
          return item;
        }
      }
    );
    setinterviewConditionsState(updatedCheckedState);

  };

  function areInterviewConditionsToggled() {
    for(let i = 0; i < interviewConditionsState.length; i++) {
      console.log(i + " " + interviewConditionsState[i]);
      if(!interviewConditionsState[i]) {
        return false;
      }
    }
    return true;
  }

  return (
    <div>
      <div className={isSchedulerDisplayed ? 'hidden' : ''}>
        <ul className="interview-conditions-list">
          {interviewConditions.map(({name, displayText}, index) => {
            return (
              <li key={index}>
                <div className="interview-condition-item">
                  <div className="left-section">
                    <input
                      type="checkbox"
                      id={`custom-checkbox-${index}`}
                      name={name}
                      value={name}
                      onChange={() => interviewConditionToggled(index)}
                      required
                    />
                    <label className="required-field">{displayText}</label>
                  </div>
                </div>
              </li>
            );
          })}
        </ul>
        <div>
          <label className="required-field block m-1">Gender</label>
          <select
            className="bg-gray-50 border border-gray-300 text-gray-900 rounded-lg focus:ring-blue-500 focus:border-blue-500 block"
            onChange={e => genderSelectionChanged(e.target.value)}>
            <option value="" selected disabled hidden>Select gender</option>
            <option value="male">Male</option>
            <option value="female">Female</option>
            <option value="notListed">Not Listed</option>
          </select>
        </div>
        <button
          className="btn btn-primary"
          onClick={displayScheduler}
          disabled={!isConfirmButtonEnabled} >
          
          Confirm
        </button>
      </div>
      <iframe
        src={calSrc}
        styleName="border: 0"
        width="100%"
        height="600"
        frameborder="0"
        className={isSchedulerDisplayed ? "" : "hidden"}>
      </iframe>
    </div>
  );
}

export default AppointmentScheduler;