import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Home';
import PrivacyPolicy from './PrivacyPolicy';
import TermsAndConditions from './TermsAndConditions';

function App() {
	return (
		<Router>
            <Routes>
                <Route exact path='/' exact element={<Home />} />
                <Route path='/PrivacyPolicy' element={<PrivacyPolicy />} />
                <Route path='/TermsAndConditions' element={<TermsAndConditions />} />
            </Routes>
        </Router>
	);
}

export default App;
