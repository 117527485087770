import { useState, useEffect } from 'react';
import isValidEmail from './EmailValidator.js';
import { Turnstile } from '@marsidev/react-turnstile';

function EmailRegistration({isTurnstileValid, setTurnstileValid}) {
	const [email, setEmail] = useState('');
	const [showSubmitButton, setshowSubmitButton] = useState(true);
	const [showTurnstile, setShowTurnstile] = useState(true);
	const [buttonText, setButtonText] = useState("I'm interested");
	const [isButtonDisabled, setIsButtonDisabled] = useState(true);
	const [turnstileToken, setTurnstileToken] = useState('');
	const [termsAndConditionsChecked, setTermsAndConditionsChecked] = useState(false);
	const [responseText, setResponseText] = useState(null);
	const [emailAdded, setEmailAdded] = useState(false);
	useEffect(() => {
		setIsButtonDisabled(!shouldSubmitButtonBeEnabled());
	}, [email]);

	useEffect(() => {
		setIsButtonDisabled(!shouldSubmitButtonBeEnabled());
	}, [termsAndConditionsChecked]);

	useEffect(() => {
		console.log('now?');
		setIsButtonDisabled(!shouldSubmitButtonBeEnabled());
	}, [turnstileToken]);

	const addEmailToList = () => {
		console.log("we're adding the email to the list");
		let formData = new FormData();
		formData.append('token', turnstileToken);
		(async () => {
			const response = await fetch("/AddEmailToList?emailToAdd=" + email, {
			method: "POST",
			body: formData
			});
			setEmailAdded(true);
			setResponseText(response.statusText);
		})();
	};

	function emailSubmitted() {
		setshowSubmitButton(false);
		addEmailToList();
	}

	function turnstileCallback(token) {
		console.log('token set');
		setTurnstileToken(token);
	}

	function emailInputChanged(e) {
		setEmail(e.target.value);
	}

	function termsAndConditionsToggled() {
		setTermsAndConditionsChecked(!termsAndConditionsChecked);
	}

	function shouldSubmitButtonBeEnabled() {
		if(email && isValidEmail(email) && termsAndConditionsChecked && turnstileToken !== '') {
			return true;
		} else {
			return false;
		}
	}

	return (
		<div className="mb-4">
			<include src="https://challenges.cloudflare.com/turnstile/v0/api.js"></include>
			<div className="text-primary font-bold py-2 px-4 m-4 text-center" style={{ display: responseText ? 'block' : 'none' }} >
				{responseText}
			</div>
			<div style={{ display: emailAdded ? 'none' : 'block'}} >
				<label className="block text-gray-700 text-sm font-bold mb-2">
					Please enter your email if you&#39;re interested in receiving updates about Uvuli
				</label>
				<input
				className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
				value={email}
				onChange={emailInputChanged}
				id="email"
				type="text"
				placeholder="Email"/>
				<div className="left-section pt-2">
					<input
						type="checkbox"
						id='terms-and-conditions'
						name={termsAndConditionsChecked}
						onChange={termsAndConditionsToggled}
						required />
					<label className="required-field ml-2">Agree to our Terms and Conditions you blowhard</label>
				</div>
				<div className="text-center">
					<button
						className="btn btn-primary"
						style={{ display: showSubmitButton ? 'inline' : 'none' }}
						onClick={emailSubmitted}
						disabled={isButtonDisabled} >
						
						{buttonText}
					</button>
				</div>
			</div>
			<div className="flex justify-center pt-2" style={{ display: emailAdded	? 'none' : 'flex' }}>
				<Turnstile siteKey="0x4AAAAAAAjstFUDnC14PVv6" onSuccess={turnstileCallback} />
			</div>
		</div>
	);
}

export default EmailRegistration;