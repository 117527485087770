import photoOfRichy from './sexyrexy.jpeg';

function AboutUvuli() {

  return (
    <div class="pt-4 grid grid-cols-1 md:grid-cols-3 grid-flow-row gap-4">
      <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 border-secondary">
        <p>
          Tired of modern dating apps? So are we.
          <br/>
          <br/>
          At uheart we are dedicated to not only helping you find love, but to keep it.
          <br/>
          <br/>
          Other modern dating apps help you find a match, but when the going gets tough they're only too happy to provide a space for you to retreat to find someone new. And someone new again. And again, all without learning anything new, while you pay for premium features.
          <br/>
          <br/>
          uheart is different. We will help you find and maintain love with potential features like:
          <br/>
          <br/>
          - Detailed matchmaking
          <br/>
          - Date ideas
          <br/>
          - Dating coaching
          <br/>
          - Relationship coaches
          <br/>
          - Community questions and discussions
          <br/>
          - Photo validation to avoid catfishing
          <br/>
          - Feedback options if you feel misled by a match
          <br/>
          <br/>
          And a lot more!
          <br/>
          <br/>
          uheart will provide all this at a low, monthly subscription fee to ensure high-quality profiles and matches for long-term love.
          <br/>
          <br/>
          Interested in helping build our Emotionally-Intelligent Dating community? Sign up with your email today.
        </p>
      </div>

      <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 border-secondary">
        <p>
          My name is Richy Kandora and I am a professional software engineer who, unfortunately, also has years of first hand experience with the modern dating app market. As someone who had been looking for a long-term relationship, it was eye-opening to me to see how many "bad actor" businesses there are in the dating space. Thus, over the years through my own personal experience, discussing with friends and family and building a great rapport with my therapist, I had been able to put in a bit of thought as to what I believed to be wrong with dating apps and how they can be improved. Uvuli is not only a business idea of mine but a geniune passion; dating can be emotionally trying and having gone through the rigors of it myself, I feel like I'm in a position to introduce an app that people trust and actually enjoy using. 
          <br/>
          <br/>
          Outside of Uuvli, I am a big sports fan (both playing and watching them) and trivia geek. The idea of continuous improvement has always appealed to me which lends itself to both of those areas: there is always something to get better at or learn (especially in golf!) and I'm always looking for opportunities to develop my skills or knowlege. 
        </p>
        <div class="pt-8">
          <img class="object-center" src={photoOfRichy}  alt="photo" />
        </div>
      </div>
      <div class="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 border-secondary">
        <p>
          My name is Richy Kandora and I am a professional software engineer who, unfortunately, also has years of first hand experience with the modern dating app market. As someone who had been looking for a long-term relationship, it was eye-opening to me to see how many "bad actor" businesses there are in the dating space. Thus, over the years through my own personal experience, discussing with friends and family and building a great rapport with my therapist, I had been able to put in a bit of thought as to what I believed to be wrong with dating apps and how they can be improved. Uvuli is not only a business idea of mine but a geniune passion; dating can be emotionally trying and having gone through the rigors of it myself, I feel like I'm in a position to introduce an app that people trust and actually enjoy using. 
          <br/>
          <br/>
          Outside of Uuvli, I am a big sports fan (both playing and watching them) and trivia geek. The idea of continuous improvement has always appealed to me which lends itself to both of those areas: there is always something to get better at or learn (especially in golf!) and I'm always looking for opportunities to develop my skills or knowlege. 
        </p>
        <div class="pt-8">
          <img class="object-center" src={photoOfRichy}  alt="photo" />
        </div>
      </div>
    </div>
  );
}

export default AboutUvuli;